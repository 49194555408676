import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Alert, CircularProgress } from '@mui/material';

import { Responsive } from 'components/content';
import { Centered } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { PermissionKey, usePermissionsContext } from 'context/PermissionsContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useQueryBi } from 'pages/bi/hooks';

export function BiPageComp() {
    const { hasPermission, alertNotPermission, isLoadingPermissions } = usePermissionsContext();
    const navigate = useNavigate();
    const { isDownMd } = useBreakpoints();
    const { biName = 'INICIO' } = useParams();
    const { isSuccess, isFetching, data } = useQueryBi();

    const iframesBi = data?.data || [];
    const itemPosition = iframesBi.findIndex((bi) => bi.dsConfigBi.toLocaleLowerCase() === biName.toLocaleLowerCase()) || 0;
    const itemPermission = iframesBi.length ? iframesBi[itemPosition].dsConfigBi.toUpperCase() : biName;
    const permissionKeyStr = `BI_${itemPermission}_VISUALIZAR` as PermissionKey;

    useEffect(() => {
        const navigateToComunicado =
            itemPermission === 'INICIO' && !isLoadingPermissions && !hasPermission([permissionKeyStr]) && hasPermission(['COMUNICADO_VISUALIZAR']);

        if (navigateToComunicado) {
            navigate('/comunicado');
        }
    }, [isLoadingPermissions, permissionKeyStr]);

    /**
     * Retorna a url do iframe de acordo com o tamanho da tela.
     */
    const getUrl = (): string => {
        const { dsUrlMobile, dsUrl } = iframesBi[itemPosition];

        if (isDownMd && dsUrlMobile) {
            return dsUrlMobile;
        }

        return dsUrl;
    };

    if (itemPermission && !hasPermission([permissionKeyStr])) {
        if (itemPermission === 'INICIO') {
            return null;
        }

        return alertNotPermission();
    }

    return (
        <OneColumn maxWidth={false} title={'Indicadores'}>
            {isFetching && (
                <Centered>
                    <CircularProgress />
                </Centered>
            )}

            {isSuccess && iframesBi.length && (
                <Block key={iframesBi[itemPosition].idConfigBi}>
                    <Responsive height={iframesBi[itemPosition].vlAltura}>
                        <iframe
                            title={iframesBi[itemPosition].dsConfigBi}
                            src={getUrl()}
                            id={`iframe_bi_${iframesBi[itemPosition].dsUrl}`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </Responsive>
                </Block>
            )}

            {(!isSuccess || !iframesBi.length) && <Alert severity="warning">Painel BI não configurado.</Alert>}
        </OneColumn>
    );
}
