import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ConfigBiRequest } from 'pages/bi/types';

export function useQueryBi() {
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/config/configbi',
        columns: 'idConfigBi,dsConfigBi,dsUrl,dsUrlMobile,vlAltura,cdPainelBi,idTipoPainelBi',
    };

    return useQuery<ConfigBiRequest>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data));
}
